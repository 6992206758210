.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 5px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid gray;
}

input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}

.btn-submit {
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #0069d9;
}
